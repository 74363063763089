<script>
import DatePicker from "vue2-datepicker";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import AccountService from "@/services/AccountService";
import ClientService from "@/services/ClientService";
import MovementService from "@/services/MovementService";
import HelperJS from "@/functions/general";
import moment from "moment";
import {VueSignaturePad} from 'vue-signature-pad';
import * as XLSX from 'xlsx';

import 'vue2-datepicker/locale/es';

import Select2 from 'v-select2-component';

import {  mapState } from "vuex";
  


const movementService = new MovementService();
const clientService = new ClientService();
const accountService = new AccountService();
const helper = new  HelperJS();
/**
 * Invoice-list component
 */
export default {
  page: {
    title: "Movimientos",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader,
    Select2,
    VueSignaturePad,
    DatePicker },
  data() {
    return {
      title: "Movimientos",
      value: '',
      userAccounts: [],
      filterBar:{
        currency: '',
        type: '',
        from: null,
        to: null
      },
      infoPage:{
        method_payments: [
          {
            id:1,
            text: 'Efectivo'
          },
          {
            id:2,
            text: 'Transferencia' 
          },
          {
            id:3,
            text: 'Cheque' 
          },
        ],
        currencys:['MXN', 'USD'],
        view: 'index',
        example: [{id:1, text:'test'}],
        actionType:'add',
        btnActive: true,
        typeMovement: ['Retiro', 'Deposito'],
        typesInfo: [
           {
            id:0,
            text: 'Retiro'
          },
          {
            id:1,
            text: 'Deposito' 
          }
        ]
      },
      items: [
        {
          text: "Inicio",
        },
        {
          text: "Movimientos",
          active: true,
        },
      ],
      invoicelist: [
        
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      prestamistas: [],
      formAccount:{
        name: null,
        start_date: null,
        currency: 'MXN',
        interest: null,
        cut_date: null,
        amount: null,
        latest_retirement: null,
        id: null,
        client_id: null,
      },
      formBalance:{
        id: null,
        account_id: null,
        date: null,
        currency: 'MXN',
        amount: null,
        method_payment: 1,
        type: 1,
        comment: null,
        client_id: null,
        user_id: null,

      },
      fields: [
      {
          key: "client.full_name",
          label: "Nombre prestamista",
          sortable: true,
        },
        {
          key: "account.name",
          label: "Cuenta",
          sortable: true,
        },
        {
          key: "date",
          sortable: true,
          label: "Fecha",
        },
        
        {
          key: "currency",
          sortable: true,
          label: "Moneda",
        },
         {
          key: "type",
          sortable: true,
          label: "Tipo",
        },
        {
          key: "amount",
          sortable: true,
          label: "Saldo",
        },
        {
          key: "method_payment",
          sortable: true,
          label: "Forma de pago",
        },
        {
          key: "action",
          label:  "Acción",
        },
       
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
  ...mapState('auth', ['currentUser']),
    rows() {
      return this.invoicelist.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  
  created(){

    this.chargeItems();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    typePayment(value){
      let returnFinded = this.infoPage.method_payments.find(item=>item.id===value);
      return returnFinded.text;
    },
    typeMovement(value){
      return this.infoPage.typeMovement[value];
    },
    formatMoney(value){
      return helper.formatMoney(value);

    },
    formatDate(value, type='short'){
      return helper.formatDate(value, type);
    },
  onchange(option){
    console.log('onchange');    
    this.value = option.value;
  },
    chargeItems(){

        movementService.getList().then(response=>{
          this.invoicelist = response.data.movements;
        });
          clientService.getList().then(response=>{
          let prestamistas = response.data.clients;
          prestamistas.map(item=> item.text = item.full_name);

          this.prestamistas = prestamistas;
        });

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
    addMovement(){
      console.log('add movwe');
      this.infoPage.actionType = 'add';
      this.$bvModal.show('modal-standard')
      
    },
    editMovement(movement){
      //console.log('account', account);
      this.infoPage.actionType = 'edit';

      for(let prop in movement){
        this.formBalance[prop] = movement[prop];
      }
      this.formBalance.date = moment(this.formBalance.date).format('DD-MMM-YYYY');
      this.checkAccounts();


      console.log('formBalance', this.formBalance);
      this.$bvModal.show('modal-standard')
      
    },

    async formSubmitMovement(){
        let formData = {...this.formBalance};
        
          formData.user_id = this.currentUser.id;
        if( formData.id === null){
          delete formData.id;
          formData.date = moment().format('YYYY-MM-DD');
          await movementService.create(formData)
          console.log('requestResponse', );
        }else{
          
          formData.date = moment(formData.date, 'DD-MMM-YYYY').format('YYYY-MM-DD');
          delete formData.client;
          delete formData.account;
          await movementService.update(formData)
        }
        this.clearMovement();
        this.$bvModal.hide('modal-standard')
        
        this.chargeItems();
      //}
      
      // stop here if form is invalid
      this.$v.$touch();
    },
    applyFilter(){
      let params = {...this.filterBar};
      if(params.from!==null){
        params.from = moment(params.from, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
      if(params.to!==null){        
        params.to = moment(params.to, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
     
       movementService.getList(params).then(response=>{
          this.invoicelist = response.data.movements;
        });

    },
    clearAccount(){
      this.formAccount = {
        name: null,
        start_date: null,
        currency: 'MXN',
        interest: null,
        cut_date: null,
        amount: null,
        latest_retirement: null,
        id: null,
        client_id: null,
      };
    },
    clearMovement(){

      this.formBalance = {
        id: null,
        account_id: null,
        date: null,
        currency: 'MXN',
        amount: null,
        method_payment: 1,
        type: 1,
        comment: null,
        client_id: null,
        user_id: null,
      };
    },

    closeModal(nameModal){
        this.$bvModal.hide(nameModal)
    },
    checkAccounts(){
      console.log('hola');
      let client_id = this.formBalance.client_id;
      let params = {
        client_id:client_id
      }

        accountService.getList(params).then(response=>{
          let userAccounts = response.data.accounts;
          console.log(userAccounts, 'userAccounts');
          userAccounts.map(item=> item.text = item.name);
          //console.log(userAccounts, 'text');

          this.userAccounts = userAccounts;
        });
    },
    deleteElement(id){
      movementService.delete(id).then(()=>{
         this.successForm();
         this.chargeItems();
      })
    },
    successForm(){
      Swal.fire("¡Operación exitosa!", "El registro fué eliminado", "success");
    },
    
    checkCurrency(){
      let account_id = Number(this.formBalance.account_id);
      let accountFinded = this.userAccounts.find(item=> item.id === account_id);
      this.formBalance.currency = accountFinded.currency;
    },
     exportExcel(){
          /* convert state to workbook */
          let results = [...this.invoicelist]
          let dataNew = [];

          if(results.length === 0){

            
          Swal.fire("¡Operación cancelada!", "No hay movimmientos para descargar", "warning");
              return true;
          }
          results.forEach(dataResponse=>{
            //console.log('dataResponse', dataResponse);
              let dataText = {
                  'prestamista': dataResponse.client.full_name,
                  'cuenta': dataResponse.account.name,
                  'total': dataResponse.amount,
                  'method_payment': this.typePayment(dataResponse.method_payment),
                  'type': this.typeMovement(dataResponse.type),
                  'currency': dataResponse.currency,
                  'date': this.formatDate(dataResponse.date),

                  'user': dataResponse.user.name
              };
              


              dataNew.push(dataText);  
          })

          console.log(dataNew, 'test');
          //return;

          // 'state', 'cod_promotion', 'comision',
          let data = XLSX.utils.json_to_sheet(dataNew,
              {
                  header: ['prestamista', 'cuenta', 'total', 'method_payment', 'type', 'currency', 'date', 'user']
            },  { cellNF : true ,  cellText : false })
         //console.log('data', data);
          data['A1'].v = 'Prestamista'
          data['B1'].v = 'Nombre de la cuenta'
          data['C1'].v = 'Total'
          data['D1'].v = 'Método de pago'
          data['E1'].v = 'Tipo de movimiento'
          data['F1'].v = 'Moneda'
          data['G1'].v = 'Fecha'
          data['H1'].v = 'Usuario'

          /*
          ; // delete old formatted text if it exists
          XLSX.utils.format_cell(data["Y1"]); // refresh cell*/

          /*data["Y"].z = "$0.00";
          delete data["Y"].w
          XLSX.utils.format_cell(data["Y"])
          console.log(data["Y"].w); // '$1.00'*/
          const currency = '$0.00'


          const workbook = XLSX.utils.book_new()
          const filename = 'Movimientos - '+ this.formatDate(moment());

          this.formatColumn(data, 'C', currency)
          XLSX.utils.book_append_sheet(workbook, data, filename)
          XLSX.writeFile(workbook, `${filename}.xlsx`)

      },

      formatColumn(ws, col, fmt) {
          var C = XLSX.utils.decode_col(col); // 1

          /* get worksheet range */
          var range = XLSX.utils.decode_range(ws['!ref']);
          for(var i = range.s.r + 1; i <= range.e.r; ++i) {
              /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
              var ref = XLSX.utils.encode_cell({r:i, c:C});
              /* if the particular row did not contain data for the column, the cell will not be generated */
              if(!ws[ref]) continue;
              /* `.t == "n"` for number cells */
              if(ws[ref].t != 'n') continue;
              /* assign the `.z` number format */
              ws[ref].z = fmt;
          }
      },

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
         
    <div class="row">
      <div class="col-md-4">
        <div class="mb-3">
            <a 
              class="btn btn-success"  @click.prevent="routeGo('/movimientos/create')"
            > Añadir capital / Retirar capital</a
          >
            <a 
              class="btn btn-info ml-1"  @click.prevent="exportExcel()"
            ><i class="mdi mdi-download me-2"></i> Exportar </a
          >
        </div>
      </div>
      <div class="col-md-8">
        <div class="row">
        <div class="col-md-3">
        
          <select class="form-control select2" v-model="filterBar.currency">
          <option value="">Todos las monedas</option>
            <option :value="currency" v-for="currency in infoPage.currencys" :key="currency" v-text="currency"></option>
          </select>
        </div>
          <div class="col-md-3">
        
          <select class="form-control select2" v-model="filterBar.type" aria-placeholder="">
          <option value="">Todos los movimientos</option>
            <option :value="element.id" v-for="element in infoPage.typesInfo" :key="element.id" v-text="element.text"></option>
          </select>
        </div>
        <div class="col-md-6">
      
           <div class="float-end">
          <div class="form-inline mb-3">
            <div
              class="input-daterange input-group"
              data-provide="datepicker"
              data-date-format="dd M, yyyy"
              data-date-autoclose="true"
            >
             <date-picker
                          v-model="filterBar.from"
                            :first-day-of-week="1"
                            format="DD-MMM-YYYY"
                            lang="es"
                          class="form-control text-left date-custom bx-left"
                          placeholder="Fecha desde"
                          ></date-picker>
               <date-picker
                          v-model="filterBar.to"
                            :first-day-of-week="1"
                            format="DD-MMM-YYYY"
                            lang="es"
                          class="form-control text-left  date-custom bx-right"
                          placeholder="Fecha hasta"
                          ></date-picker>
              <div class="input-group-append ">
                <button type="button" class="btn btn-primary btn-custom-date" @click.prevent="applyFilter()">
                  <i class="mdi mdi-filter-variant"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        
        
       
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center fw-normal">
            Mostrar&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
          <label class="d-inline-flex align-items-center fw-normal">
            Buscar:
            <b-form-input
              v-model="filter"
              type="search"
              class="form-control form-control-sm ms-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table
        table-class="table table-centered datatable table-card-list"
        thead-tr-class="bg-transparent"
        :items="invoicelist"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(check)="data">
          <div class="custom-control custom-checkbox text-center">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`contacusercheck${data.item.id}`"
            />
            <label
              class="custom-control-label"
              :for="`contacusercheck${data.item.id}`"
            ></label>
          </div>
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript: void(0);" class="text-dark fw-bold">
            {{ data.item.id }}
          </a>
        </template>
        <template v-slot:cell(date)="data">
          <label class="custom-control-label">
            {{ formatDate(data.item.date) }}
          </label>
        </template>

        
        <template v-slot:cell(amount)="data">
          <label class="custom-control-label" :class="(data.item.type)?'text-success':'text-danger'">
            {{ formatMoney(data.item.amount) }}
          </label>
        </template>


        <template v-slot:cell(type)="data">
          <label class="custom-control-label badge rounded-pil"  :class="(data.item.type)?'bg-soft-success':'bg-soft-danger'">
            {{ typeMovement(data.item.type) }}
          </label>
        
        </template>

        <template v-slot:cell(method_payment)="data">
          <label class="custom-control-label">
            {{ typePayment(data.item.method_payment) }}
          </label>
        
        </template>

        <template v-slot:cell(status)="data">
          <div
            class="badge rounded-pill bg-soft-success font-size-12"
            :class="{ 'bg-soft-warning': data.item.status === 'Pending' }"
          >
            {{ data.item.status }}
          </div>
        </template>

        <template v-slot:cell(name)="data">
          <a href="#" class="text-body">{{ data.item.name }}</a>
        </template>
        <template v-slot:cell(download)>
          <div>
            <button class="btn btn-light btn-sm w-xs">
              Pdf
              <i class="uil uil-download-alt ms-2"></i>
            </button>
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a
                 @click="routeGo('/movimientos/info/'+data.item.id)"
                class="px-2 text-primary"
                v-b-tooltip.hover
                title="Editar"
              >
                <i class="uil uil-pen font-size-18"></i>
              </a>
            </li>
             <!--<li class="list-inline-item">
              <a
                 @click="routeGo('/cuentas/details/'+data.item.id)"
                class="px-2 text-primary"
                v-b-tooltip.hover
                title="Ver detalles"
              >
                <i class="uil uil-eye font-size-18"></i>
              </a>
            </li>-->
            <li class="list-inline-item">
              <a
                 @click="deleteElement(data.item.id)"
                class="px-2 text-danger"
                v-b-tooltip.hover
                title="Eliminar"
              >
                <i class="uil uil-trash-alt font-size-18"></i>
              </a>
            </li>
          </ul>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>

     <b-modal
                id="modal-standard"
                :title="infoPage.actionType==='edit'?'Editar movimiento':'Añadir movimiento'" 
                title-class="font-18" hide-footer
              >
          <div class="card-body">
              <div class="row">
                 <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="userName"
                        >Prestamista</label
                      >
                      <div class="col-md-9">
                        <Select2  :disabled="infoPage.actionType==='edit'" @change="checkAccounts()" class="form-control form-custom" v-model="formBalance.client_id" :options="prestamistas" :settings="{ settingOption: value, settingOption: value }" />              
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="password"
                        >Cuenta</label
                      >
                      <div class="col-md-9">
                      <Select2  @change="checkCurrency()" :disabled="infoPage.actionType==='edit'" 
                      class="form-control form-custom" v-model="formBalance.account_id" 
                      :options="userAccounts" :settings="{ settingOption: value, settingOption: value }" />              
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                        >Tipo</label
                      >
                      <div class="col-md-9">
                         <b-form-radio  :disabled="infoPage.actionType==='edit'"
                          v-model="formBalance.type"
                          class="mb-3 d-inline-block mrc-3"
                          value="1"
                          plain
                          >Ingreso</b-form-radio>
                        <b-form-radio  :disabled="infoPage.actionType==='edit'"
                          v-model="formBalance.type"
                          class=" d-inline-block mb-3 "
                          value="0"
                          plain
                          >Retiro</b-form-radio >
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                        >Fecha</label
                      >
                      <div class="col-md-9">

                          <date-picker
                           :disabled="infoPage.actionType==='edit'"
                            v-model="formBalance.date"
                            format="DD-MMM-YYYY"
                            :first-day-of-week="1"
                            lang="es"
                          ></date-picker>
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="confirmpass"
                        >Monto</label
                      >
                      <div class="col-md-9">
                        <input
                          id="b_amount"
                          type="number"
                          name="b_amount"
                          class="form-control"
                          v-model="formBalance.amount"
                           :disabled="infoPage.actionType==='edit'"
                        />
                      </div>
                    </div>
                  </div>
               <div class="col-12">
                
                    
                    <div class="form-group row mb-3">
                    
                      <label class="col-md-3 col-form-label" for="name"
                        >Moneda</label
                      >
                      <div class="col-md-9">
                        <select  disabled class="form-control select2" v-model="formBalance.currency">
                          <option :value="currency" v-for="currency in infoPage.currencys" :key="currency" v-text="currency"></option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                    
                      <label class="col-md-3 col-form-label" for="name"
                        >Forma de pago</label
                      >
                      <div class="col-md-9">
                        <select class="form-control select2" v-model="formBalance.method_payment"  :disabled="infoPage.actionType==='edit'" >
                          <option :value="payment.id" v-for="payment in infoPage.method_payments" :key="payment.id" v-text="payment.text"></option>
                        </select>
                      </div>
                    </div>
                    
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="surname"
                        >Comentarios</label
                      >
                      <div class="col-md-9">
                        <textarea
                          type="text"
                          name="surname"
                          class="form-control"
                          value="Brinkman"
                          v-model="formBalance.comment"
                        ></textarea>
                      </div>
                    </div>
                     <div class="form-group row mb-3">
                      <div class="col-md-12">
                        <div class="mb-1 mb-0">
                          <label for="productdesc">Firma</label>
                        </div>
                      <div class="container-signature mb-3">
                        <VueSignaturePad width="100%" height="300px" ref="signaturePad" />
                   
                        
                      </div>
                      <button   class="btn btn-info ms-1 float-right" @click.prevent="undo" v-if="infoPage.view === 'payment'">
                          <i class="uil uil-file-alt me-1"></i> Limpiar
                        </button>
                      </div>
                    </div>
                    <div class="box-footer">
                       <div class="col text-end ms-1">
                        <a  @click.prevent="closeModal('modal-standard')" class="btn btn-danger">
                          <i class="uil uil-times me-1"></i> Cancelar
                        </a>
                        <button   class="btn btn-success ms-1" @click.prevent="formSubmitMovement">
                          <i class="uil uil-file-alt me-1"></i> Guardar
                        </button>
                      </div>
                    </div>

                  </div>

                 </div>
     
          </div>       
  </b-modal>


  

  
  </Layout>
</template>
